import React from 'react';
import Card from '../Card/Card';


const CardList = ({ phones, onSelectPhone, onRouteChange }) => {
    return (
        <article className="ma2 br3 ba b--black-10 shadow-5">
            {
                phones.map((user,i) => {
                    return (
                        (phones[i].display)
                        ? <Card 
                            key={i} 
                            id={phones[i].id} 
                            site={phones[i].site}
                            name={phones[i].name}
                            vendor={phones[i].vendor}
                            model={phones[i].model}
                            imgUrl={phones[i].imgUrl}
                            underConstruction={phones[i].underConstruction}
                            onSelectPhone={onSelectPhone}
                            onRouteChange={onRouteChange}/>
                        : null  
                    );
                })
            }
        </article>
    );
}

export default CardList;