import React, { Component } from "react";
import log from "loglevel";

class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            signInEmail: '',
            signInPassword: '',
        }
    }

    onEmailChange = (event) => {
        this.setState({signInEmail: event.target.value})
    }

    onPasswordChange = (event) => {
        this.setState({signInPassword: event.target.value})
    }

    onSubmitSignIn = () => {
        log.info(`Trying sign in for: ${this.state.signInEmail} / ${this.state.signInPassword} `);
        fetch(process.env.REACT_APP_PHONESERVER_URL + '/signin', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                email: this.state.signInEmail,
                password: this.state.signInPassword
            })
        })
        .then(response => {
            if(response.ok){
                this.props.onRouteChange('home');
            }})
        .catch(error => log.error(error))
    }

    render() {
        return (
            <article className="br3 ba b--black-10 mv4 w-100 w-50-m w-25-l mw5 center shadow-2  z-5 " style={{backgroundColor:"transparent"}}>
                <main className="pa4 light-green">
                    <div className="measure">

                        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                            <legend className="f4 fw6 ph0 mh0 center">Sign In</legend>
                            <div className="mt3">
                                <label className="db fw6 lh-copy f6" htmlFor="email-address" >Email</label>
                                <input className="pa1 input-reset ba bg-transparent hover-bg-black hover-white w-100" onChange={this.onEmailChange} type="text" name="email-address" id="email-address" />
                            </div>
                            <div className="mv3">
                                <label className="db fw6 lh-copy f6" htmlFor="password" > Password </label>
                                <input className="b pa1 input-reset ba bg-transparent hover-bg-black hover-white w-100" onChange={this.onPasswordChange}  type="password" name="password" id="password" />
                            </div>
                        </fieldset>
                        <div className="center">
                            <input onClick={this.onSubmitSignIn} className="b ph3 pv2 input-reset ba light-green bg-transparent grow pointer f6 dib flex center" type="submit" value="Sign in" />
                        </div>
                    </div>
                </main>
            </article>
        )}
}

export default SignIn;