// NPM Package
import React from 'react';

// Custom components/files
import PhoneList from '../PhoneList/PhoneList';
import './SiteList.css';


const SiteList = ({phones, onRouteChange, onSelectPhone }) => {
    const sjPhones = phones.filter(phone => {
        return (
            phone.site.toLowerCase().includes('san jose')
        )});

    // const hoPhones = phones.filter(phone => {
    //     return (
    //         phone.site.toLowerCase().includes('home office')
    //     )});

    return (
        <section id="SiteList" >
            <ul id="sj">
                <li>
                    <input type="checkbox" id="site-1" />
                    <label htmlFor="site-1" className="ma2 br2 ba b--black-10 shadow-5 tc bg-dark-gray">San Jose</label>
                    <ul className="pa0">
                        <PhoneList phones={sjPhones} onRouteChange={onRouteChange} onSelectPhone={onSelectPhone} />
                    </ul>
                </li>
            </ul>
            {/* <ul id="ho">
                <li>
                <input type="checkbox" id="site-2" />
                    <label htmlFor="site-2" className="br2 ba b--black-10  mw8 shadow-5 tc bg-dark-gray">Home Office</label>
                    <ul className="pa0">
                        <PhoneList phones={hoPhones} onRouteChange={onRouteChange} onSelectPhone={onSelectPhone} />
                    </ul>
                </li>
            </ul> */}
        </section>
    );
}

export default SiteList;


