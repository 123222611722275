// NPM Packages
import React, { Component } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import ParticlesBg from "particles-bg";
import { TfiShiftLeft,TfiShiftRight } from "react-icons/tfi";
import log from 'loglevel';

// Layout
import SignIn from '../components/SignIn/SignIn';
import Header from '../layout/Header/Header';
import SideBar from '../layout/SideBar/SideBar';
import Main from '../layout/Main/Main';
import './App.css';

class App extends Component {
    constructor() {
        super()
        this.state = {
            phones: [],
            currentId: 1,
            searchfield: '',
            route: 'home',
            isSignedIn: false,
            noSideBar: false,
            bgTheme: 'cobweb'
        }

        log.info('App entry state:',this.state);
    }
    
    componentDidMount = () => {
        this.getPhonelist();
    }
    
    getPhonelist = () => {
        fetch(process.env.REACT_APP_PHONESERVER_URL + '/phonelist', {
            method: 'get',
            headers: {
                'Content-Type': 'application/JSON',
            }
        })
        .then(res => res.json())
        .then(data => { 
            this.setState(
                { phones: data},
                () => log.info('getPhonelist',this.state.phones)
            )
        })
    }
    
    onSearchChange = (event) => {
        this.setState(
            { searchfield: event.target.value},
            () => log.info('onSearchChange:',event.target.value)
        );     
    }
    
    onSelectChange = (event) => {
        this.setState(
            { searchfield: event.target.value},
            () => log.info('onSelectChange',event.target.value)
        );
    }
    
    onSelectPhone = (id) => {
        this.setState(
            { currentId: id }, 
            () => log.debug('onSelect Phone Id: ',this.state.currentId)
        );  
    }
    
    onRouteChange = (route) => {
        if (route === 'signout') {
            this.setState({isSignedIn: false})
        } else if (route === 'home') {
            this.setState({isSignedIn: true});
            this.setState({noSideBar: false})
        }
        
        if (route === 'phoneview') {
            this.setState({noSideBar: true})
        }

        this.setState(
            {route: route},
            () => log.debug('onRouteChange: ',route)
        );
    }
    
    changeTheme = (event) => {
        this.setState({bgTheme:event.target.value})
    }

    toggleSideBar = () => {
        (this.state.noSideBar)
        ? this.setState({noSideBar: false})
        : this.setState({noSideBar: true})

        log.debug('sidebar',this.state.noSideBar);
    }

    onSignOut = () => {
        this.setState({route: 'signin'});
    }

    render() {
        const { phones, currentId, searchfield, route, isSignedIn, noSideBar } = this.state;
        const filteredPhones = phones.filter(phone => {
            return (
                (phone.name.toLowerCase().includes(searchfield.toLowerCase()) ||
                phone.vendor.toLowerCase().includes(searchfield.toLowerCase()) ||
                phone.model.toLowerCase().includes(searchfield.toLowerCase())) &&
                phone.site.toLowerCase().includes('san jose')
            );
            // return (
            //     phone.name.toLowerCase().includes(searchfield.toLowerCase()) ||
            //     phone.vendor.toLowerCase().includes(searchfield.toLowerCase()) ||
            //     phone.model.toLowerCase().includes(searchfield.toLowerCase()) ||
            //     phone.site.toLowerCase().includes(searchfield.toLowerCase())
            // );
        });

        return !phones.length ?
            <h1>Server Unavailable</h1> : 
            <div id="layout" className="flex flex-column">
                <ParticlesBg type={this.state.bgTheme} bg={{position:"absolute",zIndex:-1, height:'100%', width:'100%'}} />
                { 
                    (route === 'home' || route === 'phoneview') 
                    ? <div >
                        <header id="layout__header" className="h3 mw9">
                            <Header 
                                onRouteChange={this.onRouteChange} 
                                isSignedIn={isSignedIn}
                                onSearchChange={this.onSearchChange}
                                onSelectChange={this.onSelectChange}
                                toggleSideBar={this.toggleSideBar}
                                changeTheme={this.changeTheme}
                                phones={this.state.phones}
                            />
                        </header>
                        <main id="layout__main" className="flex flex-row mw9">
                            <Button className="z-2 bw0 br2 bg-gray fixed light-green f3 self-center o-80"  onClick={()=> this.toggleSideBar()}>
                                { (!this.state.noSideBar)
                                  ? <TfiShiftLeft className="center"/>
                                  : <TfiShiftRight className="center"/>
                                }
                            </Button>
                            <Collapse in={this.state.noSideBar} dimension="width">
                                <aside id="layout__main__left" className="overflow-scroll">
                                    <SideBar 
                                        noSideBar={noSideBar}
                                        phones={phones}
                                        onRouteChange={this.onRouteChange} 
                                        onSelectPhone={this.onSelectPhone}
                                    />
                                </aside>
                            </Collapse>
                            <article id="layout__main__middleright" className="flex w-90">
                                <Main
                                    phones={phones}
                                    filteredPhones={filteredPhones} 
                                    id={currentId} 
                                    onRouteChange={this.onRouteChange} 
                                    onSelectPhone={this.onSelectPhone}
                                    route={route}
                                />
                            </article>
                        </main>
                    </div>
                    : ( 
                        <div id="layout_signin" className="flex justify-center items-center" style={{height:"700px"}}> 
                            <SignIn onRouteChange={this.onRouteChange}/> 
                        </div>
                    )
                }
            </div>
        
    }
}

export default App;
