import React from 'react';

const Card = ({ id, site, name, vendor, model, imgUrl, underConstruction, onSelectPhone, onRouteChange }) => {
    return (
        <div className='tc bg-light-green dib br3 pa3 ma2 grow bw2 shadow-5'>
                { 
                    (underConstruction)
                      ? <img className="absolute z-2" src='/images/under-construction.webp' title="Limited Functionality" alt="" style={{top:5, left:5, height:"40px" }}></img>
                        : <img alt=""></img>
                }
               
                <img alt='phones' src={imgUrl} width='220px' height='180px'
                    onClick={() => 
                    {
                        onRouteChange('phoneview');
                        onSelectPhone(id);
                    }} 
                />
            
                <div>
                    <h3>{name}</h3>
                    <h5>{`Site: ${site}`}</h5>
                    <h5>{`Vendor: ${vendor}`}</h5>
                    <h5>{`Model: ${model}`}</h5>
                </div>
                
        </div>
    );
}

export default Card;