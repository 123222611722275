import React from "react";
import { TbApi } from "react-icons/tb";

import './ApiNavigator.css';

const ApiNavigator = ({phone, sendCommand}) => {
    return (

      <details data-popover="down">
      <summary><TbApi alt='API' className='f1 dim light-green pa1 pointer'/></summary>
      <div>
        <dl className="ma0">
            {
                phone.apiList.map(api => {
                    return (
                        <dt key={api.command} className="pa1 dim pointer" onClick={() => sendCommand(phone.id,api.command)}>{api.command}</dt>
                    )
                })
            }
        </dl>
      </div>
    </details>
    

    )
}

export default ApiNavigator;