import React from "react";
import log from "loglevel";

const Dropdown = ({ selectChange, phones }) => {
    const vendors = [];
    const models = [];

    phones.forEach(phone => {
        if (phone.display) {
            if(!vendors.includes(phone.vendor)) {
                vendors.push(phone.vendor)
            };
    
            if(!models.includes(phone.model)) {
                models.push(phone.model)
            };
        }
    });

    vendors.sort();
    models.sort();
    
    log.info('Vendors:', vendors);
    log.info('Models:', models);

    return (
        <div className="pa2">
            <select
                className='pa1 ma1 ba b-green gb-lightest-blue br2 bg-light-green ttc' 
                type='select' 
                placeholder='vendor'
                onChange={selectChange}  
            >
                <option value="">Choose Vendor</option>
                {
                    vendors.map(vendor => {
                        return (
                            <option key={vendor} value={vendor}>{vendor}</option>
                        )
                    })
                }
            </select>
	        <select 
                className='pa1 ma1 ba b-green gb-lightest-blue br2 bg-light-green' 
                type='select' 
                placeholder='model'
                onChange={selectChange} 
            >
                <option value="">Choose Model</option>
                {
                    models.map(model => {
                        return (
                            <option key={model} className="ttu" value={model}>{model.toUpperCase()}</option>
                        )
                    })
                }
	        </select>
        </div>
    );
}

export default Dropdown;