import React from "react";


const Searchbox = ({searchfield, searchChange}) => {
    return (
        <div>
            <strong style={{color:"#90EEE9", display:"inline"}}></strong> 
            <input
                className='pa1 ba b-green gb-lightest-blue br2'
                type='search'
                placeholder='🔎 Name/Vendor/Model/Site'
                onChange={searchChange}
                style={{width:"15rem"}}
            />
        </div>
    );
}

export default Searchbox;
