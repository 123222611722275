// NPM Packages
import React from 'react';
import log from 'loglevel';

// Custom Components
import CardList from '../../components/CardList/CardList';
import Scroll from '../../components/Scroll/Scroll';
import ErrorBoundry from '../../components/ErrorBoundry/ErrorBoundry';
import PhoneView from '../../components/PhoneView/PhoneView';

const Main = ({phones, filteredPhones, id, onRouteChange, onSelectPhone, route}) => {
    if (route === 'home') {
        log.info('Setting main to ',route);
        return (
            <article id="main" className="flex bg-transparent flex-row justify-start" >
                <Scroll>
                    <ErrorBoundry>
                        <CardList 
                            phones={filteredPhones}
                            onSelectPhone={onSelectPhone}
                            onRouteChange={onRouteChange}
                        />
                    </ErrorBoundry>
                </Scroll>
            </article>
        );
    }
    else if (route === 'phoneview') {
        log.info('Setting main to ',route);
        return (
            <article id="main" className="flex bg-transparent flex-row justify-start">
                <PhoneView 
                    phone={phones[id-1]} 
                    onRouteChange={onRouteChange} 
                />
            </article>
        )
    }
}

export default Main;