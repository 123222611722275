// NPM Packages
import React, {Component} from 'react';
import {MdFeedback}  from 'react-icons/md';
import {AiOutlineTeam} from 'react-icons/ai';
import { GoSignOut } from "react-icons/go";



// Custom components/files
import Searchbox from '../../components/Searchbox/Searchbox';
import Dropdown from '../../components/Dropdown/Dropdown';
import ThemePicker from '../../components/ThemePicker/ThemePicker';
import './Header.css';

import pj from '../../../package.json';

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render(){
        return (
            <header id="header" className="flex flex-column self-center ma2 br3 ba b--black-10 shadow-5">
                <main id="header__main" className="flex flex-grow-1 flex-row items-center">
                    <aside id="header__left" className="w-25 flex items-center flex-row justify-start" style={{paddingLeft:"1%"}}>
                        <img style={{width:"30px", height:"30px"}}
                            src="/images/mark_product_ZM-phone_color-RGB.png" alt="icon" 
                        />
                        <em style={{fontFamily:"SEGA LOGO FONT", fontSize:"25px", color:"rgb(144,238,233", padding:"5px"}}> PHONELAB</em>
                        <sub className="light-green"><h6>v{pj.version}</h6></sub>
                        <img style={{width:"100px", height:"40px", marginLeft:"20px", backgroundColor:"yellow"}}
                            src="/images/internaluseonly.png" alt="icon" 
                        />
                    </aside>
                    <article id="header__middle" className="flex flex-grow-1 items-center flex-row justify-end">
                        <Dropdown selectChange={this.props.onSelectChange} phones={this.props.phones}/>
                        <Searchbox searchChange={this.props.onSearchChange}/>
                    </article> 
                    <nav id="header__right" className="flex items-center justify-end" style={{width:"16%",paddingLeft:"1%",paddingRight:"1%"}}>
                        <ThemePicker changeTheme={this.props.changeTheme} />
                        <a href={process.env.REACT_APP_FEEDBACK_URL} target="_blank" rel="noreferrer">
                            <MdFeedback title="Feedback & Issues" className="f3 dim light-green pa1 pointer" />
                        </a>
                        <a href={process.env.REACT_APP_ABOUT_URL} target="_blank" rel="noreferrer">
                            <AiOutlineTeam title="About Us" className="f3 dim light-green pa1 pointer" />
                        </a>
                        {/* <GoSignOut onClick={() => this.props.onRouteChange('signout')} className='f3 dim pa1 light-green pointer'/> */}
                    </nav>
                </main>
            </header>
        )
    }
}