// NPM Packages
import React, { Component } from "react";
import log from "loglevel";

// Custom Packages/Components/Files
import PhoneViewNav from './PhoneViewNav/PhoneViewNav';
import PhoneViewMain from './PhoneViewMain/PhoneViewMain';
import PhoneViewToolPanel from './PhoneViewToolPanel/PhoneViewToolPanel';
import './PhoneView.css';


class PhoneView extends Component {
    constructor(props) {
        super(props);
        this.state ={
            newKeyDown: '',
            screenUrl: '',
            result: '',
            phoneUrl:'',
            dialpadAssist: false,
        }
        log.info('PhoneView Entry',this.props.phone);
        this.updateScreen();
        this.getDeviceInfo(this.props.phone.id);
        this.getPhoneUrl(this.props.phone.id);
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.phone.id !== prevProps.phone.id) {
            this.updateScreen();
            this.getDeviceInfo(this.props.phone.id);
            this.getPhoneUrl(this.props.phone.id);
        }
    }
   

    componentDidMount = () => {
        // document.onkeydown = this.onKeyDown;
    }
  
    componentWillUnmount = () => {
        // document.onkeydown = null;
    }

    // onKeyDown = (event) => {
    //     this.setState({newKeyDown: event.key});
    //     this.sendKey(this.props.phone.id, event.key);
    // }
  
    onButtonClick = (key) => {
      // log.log(`Old key: ${this.state.newKeyDown} New key: ${button}`);
      // if (this.state.newKeyDown === button) log.log("Match");
      // else log.log("No Match");
      this.setState({newKeyDown: key});
      this.sendKey(this.props.phone.id, key);
  
    }
    
    toggleDialpad = () => {
        (this.state.dialpadAssist)
        ? this.setState({dialpadAssist: false}, () => log.info('Toggle Dialpad:',this.state.dialpadAssist))
        : this.setState({dialpadAssist: true}, () => log.info('Toggle Dialpad:',this.state.dialpadAssist))
    }

    sendKey = (phoneid,key) => {
        fetch(process.env.REACT_APP_PHONESERVER_URL + '/sendkey', {
            method: 'post',
            headers: {
                'Content-Type': 'application/JSON',
            },
            body: JSON.stringify({
                    id : phoneid,
                    KeyName: key
            })
        })
        .then(response => {
            log.trace('response:',response.ok);
            if(response.ok) { 
                setTimeout(() => {
                    this.updateScreen();
                },"500") 
            }
        
        })
        .catch(err => log.error(err))
    }

    sendCommand = (phoneid,command,data='') => {
        const url = process.env.REACT_APP_PHONESERVER_URL + '/sendcommand';
        let init = {
            method: 'post',
            headers: {
                'Content-Type': 'application/JSON',
            },
        };
        let initbody = {
            id: phoneid,
            command: command,
        };

        if(data !== '') {
            initbody = Object.assign({data: data}, initbody);
        }

        init = Object.assign({body: JSON.stringify(initbody)}, init);
        
        log.info('sendCommand URL:',url,' Init:',init);
        
        fetch(url,init)
            .then(response => response.text().then(log.debug('received response:',response)))
            .then(text => {
                try {
                    setTimeout(() => {
                        this.updateScreen();
                    },"2000"); 
                    // Try to parse JSON, if there data assign it to result.
                    const data = JSON.parse(text);
                    this.setState(
                        {result: data},
                        () => log.info('sendCommand response:',this.state.result)
                    )
                } catch(err) {
                    // Data may not be in JSON format, try xml or text
                    this.setState(
                        {result: {text}},
                        () => log.info('sendCommand response:',this.state.result)
                    )

                }
            })
        .catch(err => log.error(err))
    }

    getConfig = (phoneid) => {
        this.sendCommand(phoneid,'getConfig');
    }

    getDeviceInfo = (phoneid) => {
        this.sendCommand(phoneid,'getDeviceInfo');
    }

    getPhoneUrl = (phoneid) => {
        fetch(process.env.REACT_APP_PHONESERVER_URL + '/phoneUrl', {
            method: 'post',
            headers: {
                'Content-Type': 'application/JSON',
            },
            body: JSON.stringify({
                id : phoneid,
            })
        })
        .then(res => res.json())
        .then(data => 
            this.setState(
                { phoneUrl: data },
                () => log.info('getPhoneUrl',this.state.phoneUrl)
            )
        )
    }

    dialNumber = (phoneid,numstr) => {
        let data;
        const validatePhoneNumber = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

        if (validatePhoneNumber.test(numstr)) {
            data = {
                number: numstr,
            };
            this.sendCommand(phoneid,'dialNumber',data);
        } else {
            alert(`Invalid Number: ${numstr} (Must be 10 Digits)`);
        }
    }


    updateScreen = () => {
        let imageObjectURL;
        fetch(process.env.REACT_APP_PHONESERVER_URL + '/screencapture', {
            method: 'post',
            headers: {
                'Content-Type': 'application/JSON',
            },
            body: JSON.stringify(this.props.phone),
        })
        .then(response => response.blob())
        .then(imageBlob => {
            imageObjectURL = URL.createObjectURL(imageBlob);
            this.setState(
                {screenUrl: imageObjectURL},
                () => log.info('updateScreen'));
        })
        .catch((err) => {
            this.setState(
                {screenUrl: 'https://cdn1.iconfinder.com/data/icons/symbol-set-1/100/Untitled-2-63-512.png'},
                () => log.error('updateScreen error:',err)
            )
        })
    }

    render() {
        const {phone, onRouteChange} = this.props;

        return (
        <div id="PhoneView_Layout" className="flex items-start">
            <article id="PhoneView_Layout_Main" className="ma2 br3 ba b--black-10 w-75 shadow-5 flex overflow-scroll">
                {/* leftToolBar nav*/}
                <PhoneViewNav onRouteChange={onRouteChange} />

                {/* mainWindow Section */}
                <PhoneViewMain 
                    phone={phone} 
                    screenUrl={this.state.screenUrl} 
                    onButtonClick={this.onButtonClick}
                />
                
            </article>
            {/* rightToolPane Section */}
            <PhoneViewToolPanel 
                phone={phone} 
                newKeyDown={this.state.newKeyDown} 
                dialNumber={this.dialNumber} 
                phoneUrl={this.state.phoneUrl} 
                getDeviceInfo={this.getDeviceInfo}
                getConfig={this.getConfig} 
                result={this.state.result}
                sendCommand={this.sendCommand}
                toggleDialpad={this.toggleDialpad}
                dialpadAssist={this.state.dialpadAssist}
                onButtonClick={this.onButtonClick}
            />  
        </div>
        )
    }
}
    

export default PhoneView;





//Source: https://stackoverflow.com/questions/56312358




