import React from "react";
import { AiFillVideoCamera } from "react-icons/ai";
import { FaFileDownload } from "react-icons/fa";
import { TbWorldUpload, TbPhoneCall } from "react-icons/tb";
import {RiPhoneFindFill, RiPagesLine} from "react-icons/ri";
import {MdOutlineDialpad} from "react-icons/md";
import ReactJson from 'react-json-view';

import ApiNavigator from "../ApiNavigator/ApiNavigator";
import Dialpad from "../Dialpad/Dialpad";
import './PhoneViewToolPanel.css';

const PhoneViewToolPanel = ({phone, newKeyDown, dialNumber, phoneUrl, getDeviceInfo, getConfig, result, resultType, sendCommand, toggleDialpad, dialpadAssist, onButtonClick }) => {
    return (
        <aside id="rightToolPane" className="br3 ba b--black-10 mt2 mh2 overflow-scroll shadow-5 flex flex-column " style={{width:"25rem",height:"820px"}}>
            {/* Phone details displayed in header */}
            <header id="phoneDetails" className="ph3">
                <dl className="light-green ba b--dashed light-green flex flex-column">
                    <div className="flex justify-between ">
                        <dt className="ma1 fw3 f7">Name: {phone.name}</dt>
                        <dt className="ma1 fw3 f7">Vendor: {phone.vendor}</dt>
                    </div>
                    <div className="flex justify-between ">
                        <dt className="ma1 fw3 f7">Site: {phone.site}</dt>
                        <dt className="ma1 fw3 f7">Model: {phone.model}</dt>
                    </div>
                    <dt className="ma1 fw3 f7 center">{`Key/Button Press: ${newKeyDown}`}</dt>
                </dl>
            </header>
            {/* Call Bar */}
            <div id="callBar" className="flex items-center center">
                        <TbPhoneCall 
                            title="Enter number to dial and click" 
                            alt='Navigate APIs' 
                            className='f2 dim light-green pa2 pointer'
                            onClick={() => dialNumber(phone.id, document.getElementById("phone-number").value)}
                        />
                        <input 
                            id="phone-number" 
                            className="input-reset ba b--black-20 pa2 w4 " 
                            type="text" 
                            aria-describedby="name-desc"
                            placeholder="8001234567" 
                        />
            </div>
            {/* Tool Pane Buttons */}
            <div id="toolPaneButtons" className="flex center items-center ">
                <MdOutlineDialpad title="Show Dialpad" className='f2 dim light-green pa2 pointer'
                    onClick={() => toggleDialpad()}
                />
                <a href={phoneUrl} target="_blank" rel="noreferrer">
                    <TbWorldUpload title="Web Portal Login: User Password: guest" alt='Web Portal' className='f2 dim light-green pa2 pointer'/>
                </a>
                <a href={phone.datasheetUrl} target="_blank" rel="noreferrer">
                    <RiPagesLine title="Datasheet" alt='Datasheet' className='f2 dim light-green pa2 pointer'/>
                </a>
                <RiPhoneFindFill title="Get Device Info" alt='Get Device Info' className='f2 dim light-green pa2 pointer'
                    onClick={()=> getDeviceInfo(phone.id)}
                />
                <FaFileDownload title="Get Configuration" alt='Get Config' className='dim light-green pa2 pointer' style={{fontSize:"1.9rem"}}
                    onClick={()=> getConfig(phone.id)}
                />
                <ApiNavigator phone={phone} sendCommand={sendCommand}/>
                <AiFillVideoCamera title="Open Video Feed (TBD)" alt='Video Feed' className='f2 dim gray pa2 pointer'/>
            </div>

            {/* Dialpad */}
            <div className="center">
                <Dialpad phone={phone} onButtonClick={onButtonClick} dialpadAssist={dialpadAssist} />
            </div>
            
            {/* Console/Output Window */}
            <div id="consoleWindow" className="br2 black bg-near-black overflow-scroll pa1 center" style={{minHeight:"590px",maxHeight:"590px"}}>
            <ReactJson 
                src={result} 
                name={false}
                displayDataTypes={false} 
                displayObjectSize={false} 
                style={{fontSize:"12px",backgroundColor:"#111111",minWidth:"20rem"}}
                theme="monokai" />   
            </div>
        </aside>
    )
}

export default PhoneViewToolPanel;