import React from "react";
import { AiFillCaretLeft } from 'react-icons/ai';

const PhoneViewNav = ({onRouteChange}) => {
    return (
        <nav id="leftToolBar" className='pa flex flex-column'>
        <AiFillCaretLeft onClick={() => onRouteChange('home')} className='f3 dim light-green pa2 pointer' />
        {/* <AiOutlineExpand onClick={() => console.log('Enter fullscree function')} className='f3 dim light-green pa2 pointer'/> */}
    </nav>
    )
}

export default PhoneViewNav;