import React from "react";

const ThemePicker = ({changeTheme}) => {
    return (           
        <div>
            <select
                id="themePicker"
                className='pa1 ma3 ba b-green gb-lightest-blue br2 bg-light-green' 
                type='select' 
                placeholder='Theme'
                onChange={changeTheme}  
            >
                <option value="circle">Themes</option>
                <option value="circle">Circles</option>
                <option value="ball">Balls</option>
                <option value="lines">Lines</option>
                <option value="thick">Thick</option>
                <option value="cobweb">Cobwebs</option>
                <option value="polygon">Polygons</option>
                <option value="square">Squares</option>
                <option value="tadpole">Tadpoles</option>
                <option value="fountain">Fountain</option>
                <option value="random">Random</option>
            </select>
    </div>
    )
}

export default ThemePicker;