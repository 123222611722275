import React from "react";
import ImageMapper from 'react-img-mapper';

const Dialpad = ({phone, onButtonClick, dialpadAssist}) => {
    return (
        <div className="z-4" >
        {
            (dialpadAssist === true)
            ? <ImageMapper 
                    height={250}
                    width={250}
                    src={phone.dialpadImgUrl}
                    map={phone.dialpadButtonMap}
                    onClick={(area) => { onButtonClick(area.name); } }
                    areaKeyName="dialpad"
        />
            : <img alt=""></img>
        }
        </div>
    )
}

export default Dialpad;