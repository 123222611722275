import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './containers/App';
import 'tachyons';
import reportWebVitals from './reportWebVitals';
import log from 'loglevel';
import remote from 'loglevel-plugin-remote';

log.setLevel("WARN");
const customPlain = log => `[${log.timestamp}]${log.level.label.toUpperCase()}:${log.message}`;

remote.apply(log, { url: process.env.REACT_APP_PHONESERVER_URL + '/logger' , format: customPlain});

log.info("App Load");
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <App />
      //   <React.StrictMode>
      //   <App />
      // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
