import React from "react";
import ImageMapper from 'react-img-mapper';

const PhoneViewMain = ({phone, screenUrl, onButtonClick}) => {
    return (
        <main id="mainWindow" className="pr4 pt3" >
            <div className="relative dib">
                <ImageMapper 
                    height={phone.pvImgHeight}
                    width={phone.pvImgWidth}
                    src={phone.pvImgUrl}
                    map={phone.buttonMap}
                    onClick={(area) => { onButtonClick(area.name); } }
                    areaKeyName="phone"
                />
                <svg style={{                                    
                    top:phone.screen.y,
                    left:phone.screen.x,
                    width:phone.screen.width,
                    height: phone.screen.height,
                    position:'absolute',
                    borderRadius:'.125rem',
                    zIndex: 1}}>
                    <defs>
                        <pattern id="pattern1" height="100%" width="100%" patternContentUnits="objectBoundingBox">
                            <image height="1" width="1" preserveAspectRatio="none" href={screenUrl} />
                        </pattern>
                    </defs>
                    <rect fill="url(#pattern1)" x='0' y='0' width={phone.screen.width} height={phone.screen.height}/>
                </svg>
                {
                    (phone.underConstruction)
                    ? <img className="absolute z-2" src='/images/under-construction.webp' title="Limited Functionality" alt=""  style={{top:0, left:(phone.pvImgWidth/2), height:"80px" }}></img>
                    : <img alt=""></img>
                }
            </div>
        </main>
    )
}

export default PhoneViewMain;