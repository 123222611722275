// NPM Package
import React from 'react';

// Custom components/files
import './PhoneList.css';

const PhoneList = ({phones, onRouteChange, onSelectPhone }) => {
  const polyPhones = phones.filter(phone => phone.vendor.toLowerCase().includes('poly'));
  const yealinkPhones = phones.filter(phone => phone.vendor.toLowerCase().includes('yealink'));

  const handleClick = (id) => {
    onRouteChange('phoneview');
    onSelectPhone(id);
  }

  return (
    <section id="PhoneList" className="mv3 mw9" style={{ width: '92%', lineHeight:'0.5' }}>
      <p></p>
        <h5><i id="online">Online /</i><i id="offline">Offline</i></h5>
      <ul id="poly">
        <li>
          <input type="checkbox" id="list-item-1" />
          <label htmlFor="list-item-1"><h5>Poly</h5></label>
          <ul>
            {
              polyPhones.map(phone => (
                  (phone.display)
                  ? (
                      <li key={phone.id} id="phone-row" onClick={() => handleClick(phone.id)}> 
                      <h5>{phone.name}</h5>
                      </li>
                    )
                    : 
                    (
                      <li key={phone.id} id="offline" > 
                      <h5>{phone.name}</h5>
                      </li>
                    )
              ))
            }
          </ul>
        </li>
      </ul>
      <ul id="yealink">
        <li>
          <input type="checkbox" id="list-item-2" />
          <label htmlFor="list-item-2"><h5>Yealink</h5></label>
          <ul>
            {
              yealinkPhones.map(phone => (
                (phone.display)
                  ? (
                      <li key={phone.id} id="phone-row" onClick={() => handleClick(phone.id)}> 
                      <h5>{phone.name}</h5>
                      </li>
                    )
                  :
                    (
                      <li key={phone.id} id="offline" > 
                      <h5>{phone.name}</h5>
                      </li>
                    )
              ))
            }
          </ul>
        </li>
      </ul>
    </section>
  );
}

export default PhoneList;
