// NPM Packages
import React from 'react';
import log from 'loglevel';

// Custom
import SiteList from '../../components/SiteList/SiteList';


const SideBar = ({ phones, noSideBar, onRouteChange, onSelectPhone }) => {
    if(noSideBar) {
        log.info(noSideBar);
        return;
    }   
    else { 
        return (
            <article id="sidebar" className="flex bg-transparent ma2 br3 ba b--black-10 shadow-5" >
                <SiteList 
                    phones={phones} 
                    onRouteChange={onRouteChange} 
                    onSelectPhone={onSelectPhone}
                />
            </article> 
            
        )
    }
}

export default SideBar;